<template>
  <div
    v-if="$route.name !== 'apps-e-commerce-shop-public'"
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <b-img :src="appLogoImage" alt="logo" style="width: 100%" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <br /><br /><br />

    <!-- main menu content-->
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItemsByUserRole"
        class="navigation navigation-main"
      />
    </perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItemsAdmin from "@/navigation/vertical/admin";
import navMenuItemsCashier from "@/navigation/vertical/cashier";
import navMenuItemsOther from "@/navigation/vertical/other";

import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    PerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    navMenuItemsByUserRole() {
      if (this.isCashier) {
        return navMenuItemsCashier;
      }

      if (this.isAdmin) {
        return navMenuItemsAdmin;
      }

      if (this.isOther) {
        return navMenuItemsOther;
      }
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
    isAdmin() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "admin"
      );
    },
    isOther() {
      let student = this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "student"
      );
      let representative = this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "representative"
      );
      let teacher = this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "teacher"
      );

      if (student || representative || teacher) {
        return true;
      }
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItemsAdmin,
      navMenuItemsCashier,
      navMenuItemsOther,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
