<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" v-if="$route.name !== 'apps-e-commerce-shop-public'">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col 1-->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="btn-icon rounded-circle"
        @click="refreshPage"
      >
        <feather-icon icon="RefreshCwIcon" />
      </b-button>
      <cart-dropdown v-if="!isCashier"/>
      <approve-QR v-if="isCashier || isAdmin"/>
      <Notification v-if="isCashier"/>
      <search-QR v-if="isCashier || isAdmin"/>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name }}
            </p>
            <span class="user-status">{{ userData.email }}</span>
          </div>
<!--           <b-avatar 
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
            :text="userData.name.charAt(0) + userData.name.charAt(1)"
          /> -->
          <b-link :to="{ name:'profile-user', params: { id: userData.id }  }">
            <img 
              class="profile-icon" 
              :src="perfilUrl"
            />
          </b-link>
          
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton 
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
import ApproveQR from '@core/layouts/components/app-navbar/components/ApproveQR.vue'
import Notification from '@core/layouts/components/app-navbar/components/Notification.vue'
import SearchQR from '@core/layouts/components/app-navbar/components/SearchQR.vue'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    DarkToggler,
    CartDropdown,
    ApproveQR,
    SearchQR,
    Notification
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return  {
       
    }
  },
  computed: {
    userData() {
      return getUserData()
    },
    isCashier() {
      return this.$store.getters['user/userData'].roles.find(role => role.name == 'cashier')
    },
    isAdmin() {
      return this.$store.getters['user/userData'].roles.find(role => role.name == 'admin')
    },
    perfilUrl() {
      return this.userData?.avatar ? this.userData?.avatar : require('@/assets/images/icons/profile_icon.png')
    }
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('user/logout')
      } catch (e) {
        console.log(e)
      } finally {
        this.$router.push('/login')
      }
    },
    refreshPage() {
      window.location.reload()
    },
  },
}
</script>
