export default [
  {
    title: 'Perfil',
    route: 'profile-user',
    icon: 'TrendingUpIcon',
    customIcon: 'perfilIcon',
  },
  {
    title: 'Colegios',
    route: 'schools', 
    icon: 'AwardIcon',
    customIcon: 'colegiosIcon',
  },
  {
    title: 'Transacciones',
    route: 'transactions',
    icon: 'BarChart2Icon',
    customIcon: 'transaccionesIcon',
  },
  {
    title: 'Tienda',
    icon: 'BarChart2Icon',
    customIcon: 'tiendaIcon',
    route: "dashboard",
  },
]
