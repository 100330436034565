<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    no-caret
  >
    <template #button-content>
      <feather-icon
        :badge="resNotificactions.length"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <template :hiden="hiddenDow" #default>
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notificaciones de Ordenes</h4>
        </div>
      </li>

      <!-- Cart Items -->
      <vuescroll
        v-if="resNotificactions.length"
        :ops="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        style="height: calc(100vh - 300px)"
        tagname="li"
      >
        <div v-for="order in resNotificactions">
          <!-- Botón para eliminar la orden -->
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            pill
            @click="removeGroup(order.orderId)"
          >
            <feather-icon icon="XIcon" />
          </b-button>

          <!-- Detalles del usuario y la fecha de la orden -->
          <div class="text-center">
            <h6 class="order-user-title">{{ order.fullname }}</h6>
            <p class="order-date">{{ order.created_at }}</p>
          </div>

          <!-- Iterar sobre los items de la orden -->
          <div v-for="item in order.items">
            <b-media>
              <h6 class="cart-item-title">
                ({{ item.qty }}) - {{ item.name }}
              </h6>
              <h5 class="cart-item-price">
                {{ item.total.usd | currency({ symbol: "$", precision: 2 }) }}
                /
                {{ item.total.bs | currency({ symbol: "Bs", precision: 2 }) }}
              </h5>
            </b-media>
          </div>
        </div>
      </vuescroll>

      <p v-if="!resNotificactions.length" class="m-0 p-1 text-center">
        No hay nuevas ordenes
      </p>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BImg,
  BFormSpinbutton,
  BButton,
  BDropdownItem,
} from "bootstrap-vue";
import vuescroll from "vuescroll";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    vuescroll,
    BButton,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mounted() {
    // this.fetchInterval = setInterval(this.fetchItems, 5000); // 5000ms = 5s
  },
  beforeDestroy() {
    clearInterval(this.fetchInterval);
  },
  data() {
    return {
      fetchInterval: null,
      resNotificactions: [],
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        rail: {
          background: "#01a99a",
        },
        bar: {
          background: "#ee3926",
        },
      },
    };
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      await this.$store
        .dispatch("notifications/fetchNotifacationItems")
        .then((res) => {
          if (this.resNotificactions.length < res.length) {
            this.message(
              "Nueva orden",
              "checkCircleIcon",
              "success",
              "Tienes una nueva orden de compra"
            );
          }
          this.resNotificactions = res;
          this.loading = false;
        })
        .catch((res) => {
          this.message(
            "Orden de compra",
            "AlertCircleIcon",
            "danger",
            res.error || "Falló en la carga"
          );
          this.loading = false;
        });
    },
    async removeGroup(orderId) {
      this.loading = true;
      await this.$store
        .dispatch("notifications/fetchNotifacationItemsClose", orderId)
        .then((res) => {
          this.resNotificactions = res;
          this.loading = false;
        })
        .catch((res) => {
          this.message(
            "Orden de compra",
            "AlertCircleIcon",
            "danger",
            res.error || "Falló al cerrar la notificación"
          );
          this.loading = false;
        });
    },
    message(message, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: message,
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
