<template>
  <div>
    <div
      v-if="$route.name == 'apps-e-commerce-shop-public'"
      class="app-content content"
      style="padding: calc(3rem) 2rem 0px;"
      :class="[
        { 'show-overlay': $store.state.app.shallShowOverlay },
        $route.meta.contentClass,
      ]"
    >
      <div class="content-overlay" />
      <div class="" />
      <transition :name="routerTransition" mode="out-in">
        <div
          class="content-wrapper clearfix"
          :class="contentWidth === 'boxed' ? 'container p-0' : null"
        >
          <div
            :class="
              $route.name == 'apps-e-commerce-shop-public'
                ? ''
                : 'content-detached content-right'
            "
          >
            <div class="content-wrapper">
              <div class="content-body">
                <slot />
              </div>
            </div>
          </div>
          <portal-target name="content-renderer-sidebar-detached-left" slim />
        </div>
      </transition>
    </div>
    <div v-else>
      <div
        class="app-content content"
        :class="[
          { 'show-overlay': $store.state.app.shallShowOverlay },
          $route.meta.contentClass,
        ]"
      >
        <div class="content-overlay" />
        <div class="header-navbar-shadow" />
        <transition :name="routerTransition" mode="out-in">
          <div
            class="content-wrapper clearfix"
            :class="contentWidth === 'boxed' ? 'container p-0' : null"
          >
            <slot name="breadcrumb">
              <app-breadcrumb />
            </slot>
            <div
              :class="
                $route.name == 'apps-e-commerce-shop-public'
                  ? ''
                  : 'content-detached content-right'
              "
            >
              <div class="content-wrapper">
                <div class="content-body">
                  <slot />
                </div>
              </div>
            </div>
            <portal-target name="content-renderer-sidebar-detached-left" slim />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
};
</script>

<style></style>
