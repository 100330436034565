<template>
  <div>
    <b-nav-item v-if="$route.name !== 'apps-e-commerce-shop-public'">
      <feather-icon size="21" :icon="'CameraIcon'" @click="cameraOpen = !cameraOpen"/>
    </b-nav-item>
    <b-modal v-model="cameraOpen" centered :title="'Aprobar Transacción'" hide-footer no-close-on-backdrop>
        <qrcode-stream @decode="onDecode"></qrcode-stream>
    </b-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavItem } from 'bootstrap-vue'
import { VBModal } from 'bootstrap-vue'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItem,
    VBModal,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  setup() {
    const { skin } = useAppConfig()

    return { skin }
  },
  data(){
    return {
      cameraOpen: false,
    }
  },
  methods: {
    async onDecode (json) {
      console.log(json)
      const data = JSON.parse(json)
      await this.$store.dispatch('transactions/approve', { id: data.transactionId, params: {method: 'approve'}})
      .then(() => {
        this.cameraOpen = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Transacción aprobada con éxito',
            icon: 'checkCircleIcon',
            variant: 'success',
            text: 'Puedes notificar al cliente que la transacción ha sido aprobada',
        }})
        this.$router.push({ name: 'apps-invoice-ticket', params: { id: data.invoiceId }})
      })
    },
  },
  directives: {
    'b-modal': VBModal,
  },
}
</script>
